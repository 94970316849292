<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar0";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Лицензия </h4>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Дата публикации :</span> 9 Марта, 2021</li>
                        </ul>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Skillrank</router-link>
                                    </li>
                                    <li class="breadcrumb-item"><a href="#">Документы</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Лицензионный договор</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                          <h4 class="title"> Лицензионный договор – публичная оферта на доступ к базе данных «резюме сайта Skillrank.ru» на условиях простой (неисключительной) лицензии</h4>

                          <p>Лицом, выставившим настоящую публичную оферту, является Индивидуальный предприниматель “Гончаренко Игорь Сергеевич”, именуемое в дальнейшем “Лицензиар”.</p>

                          <h5 class="card-title"> 1. Общие положения </h5>

                          <p>1.1. Для целей настоящего договора, термины и определения имеют следующее значение:</p>

                          <ul>

                            <li>Лицензиар – Индивидуальный предприниматель “Гончаренко Игорь Сергеевич” - правообладатель исключительных прав на базу данных и сервисное программное обеспечение сайта www.Skillrank.ru, реализующее неисключительные лицензии на условиях лицензионного договора;</li>
                            <li>Лицензиар-партнер – доверенное лицо Лицензиара, уполномоченное Лицензиаром на поиск и привлечение приобретателей неисключительных лицензий на условиях лицензионного договора, на выставление счетов к лицензионному договору – публичной оферте, оформление документов по реализуемым неисключительным лицензиям, а также выполнение иной работы с приобретателями неисключительных лицензий в рамках лицензионного договора в пределах, указанных в Сертификате полномочий;</li>

                            <li>Лицензиат – приобретатель неисключительных прав (лицензий) на базу данных и сервисное программное обеспечение сайта <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>;</li>

                            <li>База данных «Вакансии и резюме сайта Skillrank.ru» представляет собой представленную в объективной форме совокупность информации - данных о вакансиях, о резюме соискателей, систематизированных таким образом, чтобы эти материалы могли быть найдены и обработаны с помощью электронной вычислительной машины (ЭВМ);</li>

                            <li> Сервисное программное обеспечение представляет собой дополнительный программный комплекс, непосредственно взаимодействующий с базой данных, не имеющий самостоятельного коммерческого значения, обеспечивающий получение Лицензиатом дополнительных возможностей при использовании Базы данных, улучшающих ее потребительские свойства, включая (но не ограничиваясь) работу через мобильные приложения, получение контактов и организацию переписки с выбранными адресатами, в том числе иными Лицензиатами, и другие.</li>

                          <li>Состав сервисного программного обеспечения может меняться Лицензиаром.</li>

                          <li>Сервисное программное обеспечение поставляется Лицензиаром Лицензиату в составе лицензии на использование базы данных и самостоятельного лицензирования не требует.</li>

                          <li>База данных и сервисное программное обеспечение представляет собой закрытый программно-информационный портал по поиску персонала и рабочих мест и по размещению данных резюме и вакансий, существующий в электронном виде на сервере Skillrank.ru по адресу:  <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>.</li>

                          </ul>

                          <p>1.2. Для целей настоящего Соглашения термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе.</p>

                          <p>1.3. Везде, где по тексту Соглашения упоминаются «дни», имеются в виду календарные дни, если Соглашением прямо не предусмотрено иное.</p>

                          <p>1.4. В случае использования в настоящем Соглашении терминов, не определенных выше, определение таких терминов производится в соответствии с текстом Соглашения. При отсутствии однозначного определения термина в тексте Соглашения Стороны руководствуются законодательством Российской Федерации.</p>

                          <p>1.5. Предложение о заключении Соглашения является бессрочным и действует в течение всего времени размещения текста Соглашения на Сайте.</p>

                          <p>1.6. Каждая из Сторон гарантирует, что обладает необходимой право- и дееспособностью, а равно всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения Соглашения.</p>


                          <h5 class="card-title">2. Предмет Договора</h5>

                            <p>2.1. Лицензиар предоставляет Лицензиату за вознаграждение в порядке и на условиях, изложенных в настоящем лицензионном договоре – публичной оферте, неисключительную лицензию на право использования базы данных сайта <a href="https://skillrank.ru/terms" class="text-primary">https://skillrank.ru/terms</a> и сервисного программного обеспечения в рамках выбранных Лицензиатом тарифов.</p>

                            <p>2.2. По лицензионному договору, оформленному Лицензиаром-партнером, Лицензиар за вознаграждение обязуется предоставить Лицензиату, привлеченному Лицензиаром-партнером, неисключительную лицензию на право использования базы данных и сервисного программного обеспечения в пределах и на условиях настоящего лицензионного договора - публичной оферты.</p>

                            <p>2.3. Заключение лицензионного договора на условиях настоящей публичной оферты производится Лицензиатом путем ее акцепта оплатой в полном объеме счета к лицензионному договору – публичной оферте или Лицензионного договора-счета на расчетный счет Лицензиара, в том числе через его финансовых агентов (Робокасса, Киви, YooKassa и т.д.). Частичная оплата или оплата по частям счета к лицензионному договору – публичной оферте акцептом не является.</p>


                          <h5 class="card-title">3. Права и обязанности Сторон</h5>


                          <p>3.1. Предоставление неисключительных лицензий на использование Базы данных осуществляется путем организации Лицензиаром
                            доступа к закрытому порталу по поиску персонала и рабочих мест и по размещению данных резюме и вакансий в электронном виде
                            по каналам связи на сервере Skillrank.ru по адресу <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>
                            через личный кабинет Лицензиата, cоздаваемый на сайте Skillrank.ru
                            при регистрации Лицензиата, в соответствии с правилами сайта и выбранным тарифом лицензионных платежей.</p>

                          <p>Платежи осуществляеются регулярно и списываются безакцептно с карты раз в неделю или месяц, в зависимости от выбранного тарифа Лицензиатом. </p>

                          <p>Соблюдение правил сайта Skillrank.ru является для Лицензиата обязательным требованием.</p>

                          <p>При регистрации и создании личного кабинета Лицензиату присваиваются уникальные логин и пароль, которые активируются Лицензиатом самостоятельно.</p>

                          <p>Использование персональных данных осуществляется в соответствии с правилами сайта Skillrank.ru, в том числе:</p>
                          <ul>

                            <li>регистрация Лицензиата – соискателя или представителя Лицензиата - юридического лица на cайте Skillrank.ru подтверждает согласие на обработку его персональных данных Лицензиаром и/или Лицензиаром-партнером любым, не запрещенным законом способом, в том числе на включение персональных данных в Базу данных и на их предоставление неограниченному кругу лиц (общедоступность) при передаче прав использования Базы данных в порядке, устанавливаемом Лицензиаром, включая (но не ограничиваясь) в целях исполнения настоящего договора, в статистических целях, в целях рекламы, в целях защиты прав Лицензиара, в целях осуществления Лицензиаром производственной деятельности;</li>

                            <li>регистрация Лицензиата – соискателя или представителя Лицензиата- юридического лица на cайте Skillrank.ru подтверждает согласие на получение регулярных рассылок сайта Skillrank.ru информационного характера по электронной почте (e-mail), указанным в личном кабинете Лицензиата на сайте Skillrank.ru.</li>

                            <li>использование Лицензиатом персональных данных, ставших доступными ему в рамках исполнения настоящего договора, в целях, отличных от целей, связанных с трудоустройством и поиском сотрудников (подбора персонала, заполнения вакансий, поиска/предложения работы), запрещается;</li>
                            <li>использование Лицензиатом персональных данных, ставших доступными ему в рамках исполнения настоящего договора, осуществляется с  соблюдением конфиденциальности персональных данных и  обеспечения безопасности при их обработке.</li>


                            </ul>
                          <p>3.2. Лицензия считается полученной в день организации Лицензиаром доступа Лицензиата к Базе данных путем активации приобретенной неисключительной лицензии в личном кабинете Лицензиата. Оплаченная лицензия подлежит активации Лицензиатом не позднее 180 календарных дней с даты ее оплаты, если более короткий срок не предусмотрен соответствующим тарифом. По истечении упомянутого срока лицензия активируется Лицензиаром автоматически.</p>

                          <p>3.3. Доступ зарегистрированного на сайте Skillrank.ru Лицензиата к Базе данных организуется Лицензиаром не позднее дня, следующего за наиболее поздней из следующих дат: датой поступления денежных средств Лицензиата в оплату приобретенной лицензии на расчетный счет Лицензиара или датой, указанной Лицензиатом в качестве даты начала доступа к Базе данных.</p>

                          <p>3.4. Лицензиар вправе отказать Лицензиату в предоставлении неисключительной лицензии или приостановить действие предоставленной неисключительной лицензии в следующих случаях:</p>
                          <ul>

                          <li>при отсутствии регистрации на сайте Skillrank.ru,</li>

                          <li>при не поступлении оплаты выбранной лицензии,</li>

                          <li>при несоответствии требованиям действующего законодательства и/или правилам сайта Skillrank.ru данных, размещаемых Лицензиатом,</li>
                          <li>при прямой или косвенной ориентированности или связи Лицензиата или его деятельности с деятельностью религиозных сект, оккультных организаций, экстремистских группировок, производителей и/или распространителей порнографической продукции и эротических услуг организаторов азартных игр и развлечений, целителей,</li>

                            <li>при использовании персональных данных, ставших доступными Лицензиату в связи с исполнением настоящего договора, в целях, отличных от целей, связанных с трудоустройством и поиском сотрудников (подбор персонала, заполнение вакансий, поиск/предложение работы),</li>

                          <li>в иных случаях, предусмотренных правилами сайта Skillrank.ru и действующим законодательством.</li>


                          </ul>
                          <p>При устранении Лицензиатом обстоятельств, послуживших основанием для отказа в предоставлении неисключительной лицензии или ее приостановления, лицензия может быть предоставлена или ее действие может быть возобновлено. При этом общий срок действия лицензии не продлевается на период приостановки или отказа.</p>

                          <p>3.5. Лицензия, выдаваемая Лицензиату по настоящему Договору, является простой (неисключительной). За Лицензиаром сохраняется право выдачи лицензий другим лицам.</p>

                          <p>3.6. Обязанность Лицензиара по предоставлению неисключительной лицензии считается выполненной с даты
                            организации доступа к Базе данных, если иное не предусмотрено конкретным тарифом. В срок не позднее
                            5-ти рабочих дней с указанной даты Лицензиар или Лицензиар-партнер направляет Лицензиату Акт о
                            реализации неисключительной лицензии на условиях лицензионного договора.В случае не получения Лицензиаром
                            или Лицензиаром-партнером подписанного со стороны Лицензиата экземпляра акта или мотивированного отказа
                            от подписания акта в течение 14 календарных дней с даты предоставления (активации) неисключительной
                            лицензии, обязательства Лицензиара считаются исполненными надлежащим образом в полном объеме и претензии
                            не принимаются.
                          </p>
                          <p>3.7. Лицензиар вправе в любое время изменить условия настоящего лицензионного договора, включая тарифы.
                            При этом Лицензиар обязан информировать Лицензиата не менее чем за 10 рабочих дней при изменении тарифов и других
                            существенных условий реализации неисключительных лицензий путем размещения информационной ссылки об изменениях в личном
                            кабинете рекрутеров Лицензиата. Действующие прайс-листы Лицензиара, тарифы, условия неисключительных лицензий, все изменения
                             и уведомления Лицензиат получает с сайта Skillrank.ru. Моментом получения информации Лицензатом считается дата публикации
                             документов на сайте. На приобретенные Лицензиатом до изменений лицензионного договора и/или тарифов, но не активированные
                             лицензии, изменения, введенные Лицензиаром на основании настоящего пункта, не распространяются.
                          </p>
                          <p>3.8. При использовании Базы данных Лицензиат обязуется соблюдать в полном объеме правила сайта Skillrank.ru, опубликованные в открытом доступе в сети интернет по адресу: www.Skillrank.ru.
                            </p>


                            <h5 class="card-title">4. Содержание лицензии</h5>

                            <p>4.1. Для целей настоящего договора неисключительная лицензия на базу данных означает право на
                              использование Базы данных и сервисного программного обеспечения в период действия лицензии в соответствии
                              с выбранным тарифом и действующими правилами сайта Skillrank.ru в следующих пределах:</p>
                              <ul class="ml-4">
                              <li> доступ к базе данных;</li>

                            <li> систематизация данных по выбранному признаку (признакам);</li>

                            <li>хранение выбранных данных в Личном кабинете пользователя;</li>

                            <li>копирование данных в установленных объеме и пределах;</li>

                            <li>дополнение, удаление , обновление и иное редактирование данных, включая комментирование резюме;</li>

                            <li>возможность визуализации данных Лицензиата для публичной демонстрации в телекоммуникационной сети
                            интернет посредством сайта Skillrank.ru;</li>

                            <li>размещение/хранение данных Лицензиата в базе данных с возможностью публичной демонстрации в
                            телекоммуникационной сети интернет посредством сайта Skillrank.ru и возможностью автовыборки данных по заданным критериям;</li>

                            <li>доступ к информации, в том числе статистической, об откликах на размещенные Лицензиатом данные и об их просмотрах.</li>
                          </ul>
                            С момента предоставления (активации) выбранная лицензия не может быть изменена.

                            <p>4.2.Неисключительная лицензия, приобретенная в рамках настоящего договора, не предоставляет право
                               перепродажи, уступки или любого иного распространения базы данных (ее части) и/или сервисного
                                программного обеспечения третьим лицам.</p>

                            <p>Запрещается копирование (скачивание) данных с применением дополнительных программно-технических средств Лицензиата.</p>

                            <p>Лицензиату запрещается передавать логин и пароль, полученные при формировании личного кабинета на
                              сайте Skillrank.ru, неуполномоченным им лицам.
                            </p>

                            <p>4.3. Лицензия, предоставляемая по настоящему Договору, действует в отношении содержимого Базы,
                              в частности в отношении программ для ЭВМ, обеспечивающих функционирование Базы, информационных
                              данных о вакансиях, резюме, а также в отношении сервисного программного обеспечения.
                              Все Программы для ЭВМ и информация расположены на сервере Skillrank.ru по адресу:
                              <a href="https://skillrank.ru" class="text-primary">https://skillrank.ru</a>.</p>

                            <p>4.4. Лицензиар гарантирует Лицензиату, что является обладателем исключительных прав на Базу данных,
                              в связи с чем вправе выступать стороной (Лицензиаром) любых лицензионных договоров на любое использование
                              Базы данных.</p>

                            <p>База данных и сервисное программное обеспечение созданы коллективом авторов – сотрудников Лицензиара.</p>

                            <p>Лицензиар также гарантирует, что в Базе не используются никакие элементы в нарушение прав третьих лиц. В случае если гарантии, содержащиеся в настоящем абзаце, будут нарушены, Лицензиар обязуется принять меры, которые обеспечат Лицензиату беспрепятственное использование передаваемых по настоящему Договору прав, а в случае невозможности обеспечить беспрепятственное использование передаваемых прав возместить Лицензиату понесенные убытки, которые могут возникнуть у Лицензиата в связи с таким нарушением гарантий.</p>

                            <p>4.5. Лицензиат не вправе использовать переданные по настоящему договору права иначе, чем это прямо
                              предусмотрено настоящим договором и приложениями к нему.</p>

                            <p>4.6. Лицензиат не вправе передавать приобретенные по настоящему договору права третьим лицам, в том
                             числе путем заключения с ними сублицензионных договоров.</p>

                            <p>4.7. Лицензиат — юридическое лицо осуществляет использование базы данных и сервисного
                              программного обеспечения Лицензиара через своих представителей (рекрутеров) путем организации
                              каждому рекрутеру отдельного личного кабинета с обособленным логином и паролем. Число рекрутеров
                              не ограничивается. Присоединение личных кабинетов рекрутеров к Лицензиату или их отсоединение
                              производится Лицензиатом самостоятельно. Возможности конкретного рекрутера определяются Лицензиатом
                              также самостоятельно в пределах выбранной лицензии. При этом Лицензиат гарантирует получение в
                              установленной форме согласия рекрутера на обработку его персональных данных Лицензиаром и/или
                              Лицензиаром-партнером в целях настоящего лицензионного договора.</p>
                              <p>
                            4.8.Сохранность логинов и паролей Лицензиата, рекрутеров Лицензиата и иных уполномоченных им лиц,
                            обеспечивается Лицензиатом. Действия пользователя, указавшего при доступе к сайту Skillrank.ru логин и пароль
                             Лицензиата (его рекрутера или иного уполномоченного лица), признаются действиями Лицензиата.
                             </p>
                             <p>
                            4.9. Лицензиар гарантирует Лицензиату работу технических и программных средств доступа к Базе данных
                            и сервисного программного обеспечения, находящихся в зоне ответственности Лицензиара, в период действия
                            лицензии. В случае, если не по вине Лицензиата использование им полученных по настоящему договору
                            прав окажется невозможным более, чем 5% времени действия конкретной лицензии, в том числе вследствие
                            увеличения Лицензиаром срока регламентных работ, срок действия указанной лицензии продлевается на время,
                             равное удвоенному количеству времени, в течение которого использование прав было невозможным.
                           </p>

                            <h5 class="card-title">5. Срок договора</h5>

                            <p>5.1.Срок действия настоящего договора в отношении конкретной лицензии определяется тарифом, выбранным Лицензиатом.
                              Если в условиях тарифа предусмотрены количественные показатели , например, количество размещения данных Лицензиата в базе
                              данных или количество иных обращений к базе данных, срок действия лицензии определяется Лицензиаром по данным статистики
                              сайта Skillrank.ru.</p>

                              <p>5.2. Настоящий Договор действует на территории РФ.</p>

                            <h5 class="card-title">6. Лицензионное вознаргрождение</h5>

                            <p>6.1. За предоставленные по настоящему договору неисключительные права Лицензиат обязуется уплатить Лицензиару
                              лицензионное вознаграждение в соответствии с выбранным тарифом на условиях полной предоплаты. Лицензионное вознаграждение
                              не облагается НДС на основании пп. 26 п. 2 ст. 149 НК РФ.</p>

                              <p>6.2. Лицензионное вознаграждение уплачивается Лицензиатом на расчетный счет Лицензиара, в том числе через его
                                финансовых агентов (Робокасса, Киви, YooKassa, и т.д.), в течение 30 дней с даты выставления Лицензиату Лицензиаром или
                                Лицензиаром-партнером соответсвующего счета , если более короткий срок не предусмотрен соответсвующим тарифом и/или счетом.
                                По истечении упомянутого срока счет и Заявка Лицензиата на предоставленияе лицензии могут быть аннулированы Лицензиаром.
                              </p>

                              <p>6.3. На основании пп.1 п. 3 ст 169 НК РФ счета-фактуры не выставляются.</p>


                            <h5 class="card-title">7. Ответственность сторон и форс-мажор</h5>

                            <p>7.1. За неисполнение или ненадлежащее исполнение Сторонами обязательств, принятых на себя в соответствии с настоящим
                              Договором, Стороны несут ответственность в соответствии с действующим российским законодательством и настоящим Договором.</p>


                            <p>7.2. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих обязательств по
                              настоящему Договору в случае действия обстоятельств непреодолимой силы, прямо или косвенно препятствующих исполнению
                              настоящего Договора, то есть таких обстоятельств, которые не зависят от воли Сторон, не могли быть ими предвидены в момент
                              заключения Договора и предотвращены разумными средствами при их наступлении (форс-мажор).</p>

                            <p>7.3. К обстоятельствам, указанным в п.7.2 настоящего Договора, относятся: война и военные действия,
                              восстание, эпидемии, землетрясения, наводнения, акты органов власти, непосредственно затрагивающие
                              предмет настоящего Договора.</p>

                            <p>7.4. Сторона, подвергшаяся действию таких обстоятельств, обязана немедленно в письменном виде
                              уведомить другую Сторону о возникновении соответствующих обстоятельств и их влиянии на исполнение
                              соответствующих обязательств по настоящему Договору.</p>

                            <p>7.5. В случае если обстоятельства непреодолимой силы длятся более месяца и не обнаруживают
                              признаков прекращения, Стороны совместно определяют дальнейшую юридическую судьбу настоящего Договора.
                            </p>


                            <h5 class="card-title">8. Условия расторжения договора</h5>

                            <p>8.1. Каждая из Сторон имеет право досрочно расторгнуть настоящий Договор путем направления письменного уведомления
                              не менее чем за 7 дней до предполагаемой даты расторжения, если другая Сторона не выполнит какое-либо существенное
                              условие настоящего Договора.</p>

                            <p>8.2. Если настоящий Договор будет расторгнут до истечения срока его действия вследствие нарушения какого-либо условия
                              Договора Лицензиатом, то Лицензиат лишается переданных ему по настоящему Договору прав и обязуется возвратить Лицензиару
                              всю документацию, связанную с Базой данных, и переданную Лицензиату, если таковая передавалась. При этом уплаченные
                              Лицензиатом денежные средства не возвращаются, действие неисключительной лицензии блокируется Лицензиаром.</p>



                            <h5 class="card-title">9. Расторжение споров</h5>

                            <p>9.1. Споры и разногласия, возникающие из настоящего Договора или в связи с ним, будут решаться Сторонами путем переговоров.</p>

                              <p>9.2. В случае недостижения согласия спор передается на рассмотрение в Арбитражный суд.</p>



                            <h5 class="card-title">10. Прочие условия</h5>
                            <p>10.1. Все действия по настоящему договору, включая консультации и иная работа с Лицензиатом, оформление договоров и
                              документов по их исполнению производятся Лицензиаром или Лицензиаром-партнером. Получение денежных средств в оплату
                              лицензионного вознаграждения, а также иные расчеты с Лицензиатом по настоящему договору производятся Лицензиаром через
                              расчетный счет.</p>

                              <p>10.2. Во всем, что не оговорено в настоящем Договоре, Стороны руководствуются действующим законодательством
                                Российской Федерации.</p>


                            <h5 class="card-title">11. Реквизиты Лицензиара</h5>
                            <ul class="list-unstyled">
                              <li>Индивидуальный предприниматель "Гончаренко Игорь Сергеевич"</li>
                              <li>Адрес: 140073, Московская Область, п.Томилино, мкр Птицефабрика, дом 34</li>
                              <li>ИНН: 667327225183</li>
                              <li>ОГРНИП: 321508100088630</li>
                              <!--
                              <li> Банковские реквизиты:</li>
                              <li>Расчётный счёт: 40802810838000241200</li>
                              <li>БИК: 044525225</li>
                              <li> Банк: ПАО СБЕРБАНК</li>
                              <li> Корр. счёт: 30101810400000000225</li>
                              <br>
                              <li> Тел: +7(909)163-5804</li>
                              -->
                              <li> Email: <a href="mailto:igor_goncharenko@skillrank.ru" class="text-primary">igor_goncharenko@skillrank.ru</a></li>
                              <li> Telegram: IgorSkillrank</li>
                            </ul>

                            <br><br>

                            <h4 class="title"> Приложение №1 к лицензионному договору – публичной оферте на доступ к базе данных «резюме сайта Skillrank.ru» на условиях простой (неисключительной) лицензии</h4>

                            <br>
                            <h4 class="title"> Cодержание лицензий по тарифам</h4>

                            <p>1. На основании Лицензионного договора в рамках тарифа на лицензию Лицензиар предоставляет Лицензиату простую
                              возмездную (неисключительную) лицензию на доступ к базе данных и сервисному программному обеспечению сайта Skillrank.ru
                              (далее «БД» и «ПО») и на использование их по прямому назначению, как это предусмотрено явными пользовательскими функциями
                              БД и ПО, включая Личный кабинет, включая (но не ограничиваясь):</p>

                              <p>1.1. Лицензии по тарифам "доступ к базе Резюме": доступ Лицензиата к БД и ПО в целях доступа к
                                резюме c возможностью: </p>
                            <ul class="ml-4">

                            <li> просмотра до 100 контактов при длительности лицензии — неограниченное время; </li>

                            <li>  просмотра любого количества резюме и контактов при длительности лицензии — 1 календарный месяц; </li>
                            <li>просмотра любого количества резюме и контактов при длительности лицензии — 1 календарный год.</li>

                            </ul>

                             Лицензия активируется в момент поступления оплаты.

                          </p>

                              <p>2. Содержание лицензий по тарифам для лицензиатов-работодателей, их длительность,
                                сроки активации могут варьироваться.
                                Перечень тарифов не
                                является окончательным. Лицензиар вправе предлагать Лицензиатам иные тарифы.</p>


                              <p>3. Актуальный размер лицензионного вознаграждения по тарифам на лицензии отражен в
                                прайс-листах Лицензиара и размещается на сайте Skillrank.ru.</p>


                              <p>4. Настоящее приложение является неотъемлемой частью Лицензионного договора – публичной оферты на доступ к базе данных
                                 «резюме сайта Skillrank.ru»
                              на условиях простой (неисключительной) лицензии</p>



                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
